.flip_card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip_card_inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip_card:hover .flip_card_inner {
  transform: rotateY(180deg);
}

.flip_card_front,
.flip_card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip_card_front {
  background-color: #bbb;
  color: black;
}

.flip_card_back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}
