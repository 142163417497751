.container {
  padding: 40px 20px 20px 20px;
  width: 99%;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}

.card_item {
  padding: 15px 30px;
  width: 22%;
  text-align: center;
}

.container .card_item:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
  cursor: pointer;
}

.container .card_inner {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 35px 20px;
  min-width: 225px;
  min-height: 315px;
  max-height: 370px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.container .card_item img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 50%;
  margin: auto;
  transition: transform 0.2s;
}

.container .card_item .userName {
  color: #222222;
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  .container .card_item {
    width: 33%;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .container .card_item {
    width: 50%;
  }
  .wrapper .header {
    font-size: 16px;
    height: 50px;
  }
}

@media screen and (max-width: 568px) {
  .container .card_item {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 320px) {
  .container .card_item {
    width: 100%;
  }
}
