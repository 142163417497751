.team-container {
  padding: 40px 10px 10px 10px;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.team-member {
  margin: 5px;
  width: 14.28%;
  text-align: center;
  margin-bottom: 8px;
}

.team-member-link {
  text-decoration: none;
}

.team-member-card {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 15px;
  min-width: 150px;
  min-height: 200px;
  max-height: 300px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.team-member-image {
  width: auto;
  height: auto;
  margin-bottom: 10px;
  border-radius: 50%;
  margin: auto;
  transition: transform 0.2s;
  transform: scale(1);
}

.team-member-image:hover {
  transform: scale(1.05);
  text-decoration: none;
}

.team-member-name {
  color: #12203a;
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.team-member-name:hover {
  text-decoration: none;
}

.team-member-role {
  color: #718096;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .team-member {
    width: 100%; /* Adjust the width to create a 2-column layout on smaller screens */
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 8px;
  }
  .team-member-card {
    padding: 10px;
    min-width: 100px;
    min-height: 100px;
  }
  .team-member-name {
    font-size: 10px;
  }
  .team-member-image {
    width: 50%;
    height: 50%;
    /* padding: 5px; */
    border-radius: 50%;
    margin: auto;
    transition: transform 0.2s;
    transform: scale(1);
  }
}
